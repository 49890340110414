"use client";
import React from "react";
import Image from "next/image";
import { List } from "flowbite-react";
import { Button, FileInput, Label, Textarea, TextInput } from "flowbite-react";
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube } from "react-icons/fa";
import Link from "next/link";

const Footer = () => {
  return (
    <>
      <section className="max-w-[40rem] mx-auto">
        <div className="p-10 border-2 border-neutral-400 m-10">
          <h4>Disclaimer</h4>
          <div>
            This website is part of the Umesh Shetty family office and is solely
            intended for brand-building purposes. It is not meant for promotion,
            marketing, or the advertisement of any services. The content
            provided is for informational and personal purposes only. UJS
            Investments does not provide any suggestions or advice regarding the
            financial market.
          </div>
        </div>
      </section>
      <div className=" bg-neutral-900 text-gray-200">
        <section className="py-10 md:py-16 lg:py-24">
          <div className="max-w-screen-xl mx-5 lg:mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-3 md:gap-10 lg:gap-24 items-start justify-between">
              <div className="col-span-1">
                <h5 className="text-white mb-4 md:mb-8 uppercase">
                  About Info
                </h5>
                <div>(Umesh Shetty Family Office)</div>
                <p>
                  Welcome to UJS Investments, a distinguished firm dedicated to
                  making strategic investments across a diverse array of
                  sectors. Our mission is to identify and invest in
                  high-potential opportunities that drive innovation, growth,
                  and value creation.
                </p>
              </div>
              <div className="col-span-1">
                <h5 className="text-white mb-4 md:mb-8 uppercase">
                  Quick Links
                </h5>
                <List unstyled className="s space-y-2">
                  <List.Item>
                    <a
                      href="/"
                      className="text-gray-200  hover:text-white py-3"
                    >
                      Home
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/about-us"
                      className="text-gray-200 hover:text-white py-3"
                    >
                      About Us
                    </a>
                  </List.Item>
                  {/* <List.Item>
                    <a
                      href="/services"
                      className="text-gray-200 hover:text-white py-3"
                    >
                      Services
                    </a>
                  </List.Item> */}
                  <List.Item>
                    <a
                      href="/career"
                      className="text-gray-200 hover:text-white py-3"
                    >
                      Career
                    </a>
                  </List.Item>
                  <List.Item>
                    <a
                      href="/contact-us"
                      className="text-gray-200 hover:text-white py-3"
                    >
                      Contact Us
                    </a>
                  </List.Item>
                </List>
              </div>
              <div className="col-span-1">
                <h5 className="text-white mb-4 md:mb-8 uppercase">
                  Contact Us
                </h5>
                <div className="mt-6">
                  <div className="flex items-start mb-6">
                    <img
                      src="/map.svg"
                      className="w-6 h-6 mr-4 self-start"
                      width={24}
                      alt="location"
                    />
                    <div className="text-gray-200">
                      503A Morya Grand, New Link Road, Andheri West, Mumbai
                      400053.
                    </div>
                  </div>
                  <div className=" items-start mb-4">
                    <Link className="flex" href="tel:+912249728755">
                      <img
                        src="/phone.svg"
                        className="w-6 h-6 mr-4 self-start"
                        width={24}
                        alt="phone"
                      />
                      <div className="text-gray-200">+022 4972 8755</div>
                    </Link>
                  </div>
                  <div className="  items-start mb-4">
                    <Link
                      className="flex"
                      href="mailto:info@ujsinvestments.com"
                    >
                      <img
                        src="/email.svg"
                        className="w-6 h-6 mr-4 self-start"
                        width={24}
                        alt="email"
                      />
                      <div className="text-gray-200">
                        info@ujsinvestments.com
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-black py-4 md:py-8 ">
          <div className="max-w-screen-xl mx-5 lg:mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-6 items-center justify-between text-gray-400">
              <div className="col-span-1">
                <p className="text-white">
                  Copyright © 2025 Ujs Investments. All Rights Reserved.
                </p>
              </div>
              <div className="col-span-1">
                <div className="flex items-center justify-center lg:justify-end space-x-3">
                  <Link
                    href="https://prateekshawebdesign.com"
                    className="text-white"
                  >
                    Website Designed By Prateeksha
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Footer;
