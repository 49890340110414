import(/* webpackMode: "eager" */ "/home/ritesh/webdesign/ujsinvestments.com/next.js/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/ritesh/webdesign/ujsinvestments.com/next.js/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/ritesh/webdesign/ujsinvestments.com/next.js/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/ritesh/webdesign/ujsinvestments.com/next.js/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/ritesh/webdesign/ujsinvestments.com/next.js/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/ritesh/webdesign/ujsinvestments.com/next.js/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ritesh/webdesign/ujsinvestments.com/next.js/src/components/Footer.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ritesh/webdesign/ujsinvestments.com/next.js/src/components/Header.js");
